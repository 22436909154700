.upload-box {
  position: relative;
  background: black;
  min-height: 100vh;
  padding: 5rem;
}

.back-link {
  color: white;
  font-family: 'Roboto Mono';
  text-decoration: none;
  position: fixed;
  top: 20px;
  left: 20px;
}

.form-headline {
  font-family: 'Abril Fatface';
  text-shadow: red 2px 2px 2px;
  color: white;
}

.upload-form {
  display: flex;
  flex-direction: column;
  
}

.upload-form * {
  font-family: 'Roboto Mono';
  margin: .5rem;
}

.upload-form > label {
  color: white;
  margin-top: 20px;
}

.upload-form > input, select {
  width: 40vw;
  border-radius: 6px;
  color: black;
}

.upload-form > button {
  width: 100px;
  color: red;
  border-radius: 6px;
}

.preview-pic {
  max-width: 200px;
  margin-left: -30px;
}
