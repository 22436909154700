.image-box {
  margin: 0 0 3em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  
}

.image-box > h4, h5 {
  color: white;
  font-family: 'Roboto Mono';
  margin: .25em;
}

.image-box > button, * button {
  margin: 10px;
  border-radius: 5px;
  color: red;
  cursor: pointer;
}


@media screen and (max-width: 414px) {
  .image-box {
    margin-left: -40px;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) {
  .image-box {
    margin: 0 2em 0 2em;
  }
}
