.detail-outer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: black;
  background-position: cover;
}

.detail-link {
  position: fixed;
  top: 1em;
  left: 1em;
  color: white;
  text-shadow: black 2px 2px;
  font-family: 'Roboto Mono';
  font-size: 1.5em;
  text-decoration: none;
}
