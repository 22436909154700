.header-box {
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #0c0910;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 0;
  height: 6rem;
  border-bottom: solid #f0f0f0 2px;
}

.header-box > h1 {
  position: relative;
  font-size: 1.75em;
  margin: 0 0 .75em 0;
  font-family: 'Abril Fatface';
  color: #f0f0f0;
  text-shadow: red 3px 3px 2px;
}

.header-box > h2 {
  position: absolute;
  top: -.9em;
  right: .55em;
  font-family: 'Amita';
  font-size: 3em;
  color: red;
  transform: rotate(25deg);
}

.header-box > :first-child {
  position: absolute;
  left: 2em;
  bottom: 0;
}

.header-box > nav > :nth-child(2) {
  position: absolute;
  left: 6em;
  bottom: 0;
}

.header-box > nav > :nth-child(3) {
  position: absolute;
  left: 9.5em;
  bottom: 0;
}

.admin-links {
  position: absolute;
  bottom: 0;
  left: 14em;
  font-family: 'Roboto Mono';

}

.admin-links > * {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  width: 95px;
  margin-right: 30px;
}

.admin-links :nth-child(3) {
  margin-left: 40px;
}

@media screen and (min-width: 1025px) {

  .header-box > h1 {
    font-size: 3em;
    margin-top: .5em;
    margin-left: 1em;
  }

  .header-box > h2 {
    font-size: 4em;
    position: relative;
    margin: 2em 0 0 0.3em;
  }

}

@media screen and (min-width: 765px) {

  .header-box > h1 {
    font-size: 2.5em;
    margin-left: 3em;
  }

  .header-box > h2 {
    font-size: 3em;
    position: relative;
    margin: 2em 0 0 0.3em;
  }
}
