.bio-outer {
  z-index: 10;
  position: absolute;
  top: 3rem;
  left: -4.5em;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  width: 15rem;
  background: black;
  border: solid white 3px;
  border-radius: 5px;
}
.bio-text {
  color: white;

}
