.list-box {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #0c0910;
  justify-content: center;
  align-items: center;
}

.image-box {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  
}

.image-box > h4, h5 {
  color: white;
  font-family: 'Roboto Mono';
  margin: .25em;
}


@media screen and (max-width: 414px) {
  .image-box {
    margin-left: -40px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1025px) {
  .big-event-list {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .picture-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .event-list {
    display: none;
  }
}
