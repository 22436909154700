.confirm-outer {
  position: fixed;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 20rem;
  border: solid white 3px;
  font-family: 'Roboto Mono';
  border-radius: 5px;
  color: white;
  background: black;
}

.confirm-outer > nav > button {
  font-size: 1.5em;
  text-shadow: black 1px 1px;
  width: 100px;
  cursor: pointer;
}
